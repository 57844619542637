






























import Vue from "vue";
import { mapGetters } from "vuex";
import FullScreenDialog from "@/components/FullScreenDialog.vue";
import MobileAppsForm from "./components/MobileAppsForm.vue";
import MobileAppsTable from "./components/MobileAppsTable.vue";
import { MobileApp } from "@/store/types";

export default Vue.extend({
  name: "MobileApps",

  components: {
    FullScreenDialog,
    MobileAppsForm,
    MobileAppsTable
  },

  data: () => ({
    fullScreenDialog: false,
    app: null as unknown | MobileApp,
    loading: false,
  }),

  computed: {
    ...mapGetters({
      apps: "mobileAppsModule/getApps",
      total: "mobileAppsModule/getTotal"
    })
  },

  created() {
    this.loading = true;
    this.$store.dispatch("mobileAppsModule/fetch")
        .finally(() => this.loading = false);
  },

  methods: {
    edit(app: MobileApp): void {
      this.app = app;
      this.openFullScreenDialog();
    },

    openFullScreenDialog(): void {
      this.fullScreenDialog = true;
    },

    closeFullScreenDialog(): void {
      this.fullScreenDialog = false;
      this.app = null;
    },
  }
})
