

































import { MobileApp } from "@/store/types";
import Vue from "vue";

export default Vue.extend({
  name: "MobileAppsTable",

  props: {
    items: { type: Array, default: null },
    loading: { type: Boolean, default: false },
    total: { type: Number, default: 0 }
  },

  data: () => ({
    page: 1,
    pageSize: 10,
    headers: [
      {
        text: "Nome do App",
        align: "start",
        value: "app_name"
      },
      {
        text: "Criado em",
        align: "start",
        value: "created_at",
      },
      {
        text: "Status",
        value: "info",
        align: "center",
        sortable: false
      },
      {
        text: "Ações",
        value: "actions",
        width: "110",
        align: "center",
        sortable: false
      }
    ] as Array<Object>
  }),

  methods: {
    onEdit(item: MobileApp): void {
      this.$emit("onEdit", item)
    },
  }
})
