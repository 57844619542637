































import { MobileApp } from "@/store/types";
import Vue from "vue";

export default Vue.extend({
  name: "MobileAppsForm",

  props: {
    app: { type: Object, default: null }
  },

  data() {
    return {
      select: [
        { text: "Sim", value: true },
        { text: "Não", value: false }
      ],
      payload: {
        _id: "",
        app_name: "",
        is_active: true,
        created_at: new Date(),
        updated_at: new Date(),
      } as MobileApp
    };
  },

  mounted() {
    if (this.app) {
      Object.assign(this.payload, this.app)
    }
  },

  methods: {
    save(): void {
      if (!this.validateForm()) return;

      if (this.app) {
        this.payload.updated_at = new Date()
        this.$store.dispatch("mobileAppsModule/update", this.payload);
      } else {
        this.$store.dispatch("mobileAppsModule/create", this.payload);
      }

      this.$emit("close");
    },

    validateForm(): Boolean {
      // if (!this.payload.sku) {
      //   this.showError("Insira o SKU da oferta!");
      //   return false;
      // }
      return true;
    },

    showError(message: string): void {
      this.$store.dispatch("app/showCustomError", message);
    },
  }
});
